import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
  useGetTaskQuery,
  useEditTaskMutation,
} from "../features/tasks/tasksApi";
import AddEdit from "../components/AddEdit";

const Edit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: task } = useGetTaskQuery(id);
  const [editTask] = useEditTaskMutation();

  const [taskData, setTaskData] = useState({
    taskName: "",
    teamMember: "",
    project: "",
    deadline: "",
  });

  useEffect(() => {
    // let tempTask = { ...task };
    // replace project with projectName
    // tempTask.projectName = tempTask.project;
    // delete tempTask.project;
    setTaskData(task);
  }, [task]);

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(taskData);
    editTask(taskData);
    navigate("/");
  };

  return (
    <AddEdit
      isEdit={true}
      taskData={taskData}
      setTaskData={setTaskData}
      handleFormSubmit={handleSubmit}
    />
  );
};

export default Edit;
