import { apiSlice } from "../api/apiSlice";

const ProjectsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProjects: builder.query({
            query: () => "/projects",
        }),
        getProject: builder.query({
            query: (projectId) => `/projects/${projectId}`,
        }),
    }),
});

export const { useGetProjectsQuery, useGetProjectQuery } = ProjectsApi;