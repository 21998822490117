import React from "react";

import { useGetProjectsQuery } from "../features/projects/projectsApi";
import { useGetTeamsQuery } from "../features/teams/teamsApi";

const AddEdit = ({
  taskData,
  setTaskData,
  isEdit = false,
  handleFormSubmit,
}) => {
  const { data: projects } = useGetProjectsQuery();
  const { data: teams } = useGetTeamsQuery();

  const getProject = (id) => {
    return projects?.find((project) => project.id === id);
  };

  const getTeam = (id) => {
    return teams?.find((team) => team.id === id);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "teamMember")
      setTaskData({ ...taskData, teamMember: getTeam(parseInt(value)) });
    else if (name === "project")
      setTaskData({ ...taskData, project: getProject(parseInt(value)) });
    else setTaskData({ ...taskData, [name]: value });
  };

  return (
    <div className="text-[#111827]">
      <div className="container relative">
        <main className="relative z-20 max-w-3xl mx-auto rounded-lg xl:max-w-none">
          <h1 className="mt-4 mb-8 text-3xl font-bold text-center text-gray-800">
            Create Task for Your Team
          </h1>

          <div className="justify-center mb-10 space-y-2 md:flex md:space-y-0">
            <form className="space-y-6" onSubmit={handleFormSubmit}>
              <div className="fieldContainer">
                <label htmlFor="lws-taskName">Task Name</label>
                <input
                  type="text"
                  name="taskName"
                  id="lws-taskName"
                  required
                  placeholder="Implement RTK Query"
                  value={taskData?.taskName}
                  onChange={handleChange}
                />
              </div>

              <div className="fieldContainer">
                <label>Assign To</label>
                <select
                  name="teamMember"
                  id="lws-teamMember"
                  required
                  value={
                    isEdit ? taskData?.teamMember?.id : taskData?.teamMember?.id
                  }
                  onChange={handleChange}
                >
                  <option value="" hidden>
                    Select Member
                  </option>
                  {teams?.map((team) => (
                    <option key={team.id} value={team.id}>
                      {team.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="fieldContainer">
                <label htmlFor="lws-projectName">Project Name</label>
                <select
                  id="lws-projectName"
                  name="project"
                  required
                  value={isEdit ? taskData?.project?.id : taskData?.project?.id}
                  onChange={handleChange}
                >
                  <option value="" hidden>
                    Select Project
                  </option>
                  {projects?.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.projectName}
                    </option>
                  ))}
                </select>
              </div>

              <div className="fieldContainer">
                <label htmlFor="lws-deadline">Deadline</label>
                <input
                  type="date"
                  name="deadline"
                  id="lws-deadline"
                  required
                  value={taskData?.deadline}
                  onChange={handleChange}
                />
              </div>

              <div className="text-right">
                <button type="submit" className="lws-submit">
                  {isEdit ? "Edit" : "Save"}
                </button>
              </div>
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};

export default AddEdit;
