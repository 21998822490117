import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header";

import Home from "./pages/Home";
import Add from "./pages/Add";
import Edit from "./pages/Edit";
import Error404 from "./pages/Error404";

import './App.css';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/add" element={<Add />} />
        <Route path="/edit/:id" element={<Edit />} />

        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
}

export default App;
