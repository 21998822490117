import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import AddEdit from "../components/AddEdit";
import { useAddTaskMutation } from "../features/tasks/tasksApi";

const Add = () => {
  const navigate = useNavigate();
  const [addTask] = useAddTaskMutation();

  const [taskData, setTaskData] = useState({
    taskName: "",
    teamMember: "",
    project: "",
    deadline: "",
    status: "pending",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(taskData);
    addTask(taskData);
    navigate("/");
  };

  return (
    <AddEdit
      taskData={taskData}
      setTaskData={setTaskData}
      handleFormSubmit={handleSubmit}
    />
  );
};

export default Add;
