import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  selectedProjects,
  removeProject,
} from "../../features/projects/projectsSlice";

const SidebarProjectItem = ({ project }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    const checkedValue = e.target.checked;

    setChecked(checkedValue);
    if (checkedValue) {
      dispatch(selectedProjects(project));
    } else {
      dispatch(removeProject(project.id));
    }
  };

  return (
    <div className="checkbox-container">
      <input
        type="checkbox"
        checked={checked}
        className={project.colorClass}
        onChange={handleCheckboxChange}
      />
      <p className="label">{project.projectName}</p>
    </div>
  );
};

export default SidebarProjectItem;
