import { createSlice } from "@reduxjs/toolkit";

const initialState = [
    {
        "id": 1,
        "projectName": "Scoreboard",
        "colorClass": "color-scoreboard"
    },
    {
        "id": 2,
        "projectName": "Flight Booking",
        "colorClass": "color-flight"
    },
    {
        "id": 3,
        "projectName": "Product Cart",
        "colorClass": "color-productCart"
    },
    {
        "id": 4,
        "projectName": "Book Store",
        "colorClass": "color-bookstore"
    },
    {
        "id": 5,
        "projectName": "Blog Application",
        "colorClass": "color-blog"
    },
    {
        "id": 6,
        "projectName": "Job Finder",
        "colorClass": "color-jobFinder"
    }
];

const projectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        selectedProjects: (state, action) => {
            return [...state, action.payload];
        },
        removeProject: (state, action) => {
            return state.filter((project) => project.id !== action.payload);
        }
    },
});

export const { selectedProjects, removeProject } = projectsSlice.actions;
export default projectsSlice.reducer;

