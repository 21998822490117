/**
 * 
 * @param {String} key selectedProjects or removeProject
 * @param {Number} value id of the project
 * @param {String} action add or remove
 * @returns void
 */
export const handleArrItemToLocalStorage = (key, value, action) => {
    const storedArr = JSON.parse(localStorage.getItem(key)) || [];

    if (action.toLowerCase() === "add") {
        storedArr.push(value);
    } else {
        const index = storedArr.indexOf(value);
        storedArr.splice(index, 1);
    }

    localStorage.setItem(key, JSON.stringify(storedArr));

    return;
};

/**
 * 
 * @param {Array} arr [1, 2, 3]
 * @param {Number} value 1
 * @returns Boolean
 */
export const valueExistInArr = (arr, value) => {
    return arr.includes(value);
};

export const mapTaskToDB = (task) => {
    const { name, status, projectId } = task;
    return {
        name,
        status,
        projectId,
    };
};

/**
 * 
 * @param {Date} date 2023-03-11
 * @returns String 11 March 2023
 */
export const mapDateToString = (date) => {
    // conver 2023-03-11 to 11 March 2023
    const dateArr = date.split("-");
    const year = dateArr[0];
    const month = dateArr[1];
    const day = dateArr[2];

    const monthName = new Date(year, month - 1, day).toLocaleString("default", {
        month: "long",
    });

    return `${day} ${monthName} ${year}`;
};