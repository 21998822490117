import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import TaskItem from "./TaskItem";
import { useGetTasksQuery } from "../../features/tasks/tasksApi";

const TaskList = () => {
  const { projects, search } = useSelector((state) => state);
  const {
    data: tasks,
    isFetching,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetTasksQuery();

  const [filteredTasks, setFilteredTasks] = useState([]);
  const [finalTasks, setFinalTasks] = useState([]);

  useEffect(() => {
    if (!isFetching) {
      if (projects.length > 0) {
        // check if the task.project.id is in the projects array id
        const tasksArr = tasks.filter((task) =>
          projects.some((project) => project.id === task.project.id)
        );
        setFinalTasks(tasksArr);
        setFilteredTasks(tasksArr);
      } else if (!isLoading && tasks.length > 0) {
        setFilteredTasks(tasks);
        setFinalTasks(tasks);
      }
    }
  }, [isFetching, isLoading, projects, tasks]);

  useEffect(() => {
    if (isSuccess) {
      if (search !== "") {
        const filteredArr = filteredTasks.filter((task) =>
          task.taskName.toLowerCase().includes(search.toLowerCase())
        );
        setFinalTasks(filteredArr);
      } else {
        setFinalTasks(filteredTasks);
      }
    }
  }, [filteredTasks, isSuccess, search, tasks]);

  return (
    <div className="lws-task-list">
      {isLoading && <div>Loading...</div>}
      {isError && <div>{error.message}</div>}
      {!isLoading && !isError && filteredTasks.length > 0 ? (
        finalTasks.map((task) => <TaskItem key={task.id} task={task} />)
      ) : (
        <div align="center">
          <strong style={{ color: "red" }}>No Tasks Found</strong>
        </div>
      )}
    </div>
  );
};

export default TaskList;
