import { apiSlice } from "../api/apiSlice";

const TasksApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTasks: builder.query({
            query: () => "/tasks",
        }),
        getTask: builder.query({
            query: (id) => `/tasks/${id}`,
        }),
        addTask: builder.mutation({
            query: (body) => ({
                url: "/tasks",
                method: "POST",
                body,
            }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                // console.log("arg", arg);
                let result = await queryFulfilled;
                // console.log("result", result);
                dispatch(
                    apiSlice.util.updateQueryData(
                        "getTasks",
                        undefined,
                        (draft) => {
                            const jsonDraft = JSON.parse(JSON.stringify(draft));
                            jsonDraft.push(result.data);
                            return jsonDraft;
                        }
                    )
                );
            }
        }),
        editTask: builder.mutation({
            query: (body) => ({
                url: `/tasks/${body.id}`,
                method: "PUT",
                body,
            }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                console.log("arg", arg);
                let result = await queryFulfilled;
                console.log("result", result);
                dispatch(
                    apiSlice.util.updateQueryData(
                        "getTasks",
                        undefined,
                        (draft) => {
                            let jsonDraft = JSON.parse(JSON.stringify(draft));

                            jsonDraft = jsonDraft.map((task) => {
                                if (task.id === result.data.id) {
                                    return result.data;
                                }
                                return task;
                            });
                            return jsonDraft;
                        }
                    )
                );
            }
        }),
        updateStatus: builder.mutation({
            query: ({ id, status }) => ({
                url: `/tasks/${id}`,
                method: "PATCH",
                body: {
                    status,
                }
            }),
        }),
        deleteTask: builder.mutation({
            query: (id) => ({
                url: `/tasks/${id}`,
                method: "DELETE",
            }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                const taskUpdate = dispatch(
                    apiSlice.util.updateQueryData(
                        "getTasks",
                        undefined,
                        (draft) => {
                            const jsonDraft = JSON.parse(JSON.stringify(draft));
                            return jsonDraft.filter((task) => task.id !== arg);
                        }));

                try {
                    await queryFulfilled;
                } catch (err) {
                    // console.log("err", err);
                    setTimeout(() => {
                        taskUpdate.undo();
                    }, 2000);
                }
            }
        }),
    }),
});

export const { useGetTasksQuery, useGetTaskQuery, useAddTaskMutation, useEditTaskMutation, useUpdateStatusMutation, useDeleteTaskMutation } = TasksApi;
