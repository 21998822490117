import { apiSlice } from "../api/apiSlice";

const TeamsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTeams: builder.query({
            query: () => "/team",
        }),
        getTeam: builder.query({
            query: (teamId) => `/team/${teamId}`,
        }),
    }),
});

export const { useGetTeamsQuery, useGetTeamQuery } = TeamsApi;