import React from "react";

import { useGetProjectsQuery } from "../../features/projects/projectsApi";
import SidebarProjectItem from "./SidebarProjectItem";

const SidebarProjects = () => {
  const { data: projects, isLoading, isError, error } = useGetProjectsQuery();

  return (
    <div>
      <h3 className="text-xl font-bold">Projects</h3>
      <div className="mt-3 space-y-4">
        {isLoading && <div>Loading...</div>}
        {isError && <div>{error.message}</div>}
        {!isLoading && !isError && projects.length > 0 ? (
          projects.map((project) => (
            <SidebarProjectItem key={project.id} project={project} />
          ))
        ) : (
          <div align="center">
            <strong style={{ color: "red" }}>No Projects Found</strong>
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarProjects;
