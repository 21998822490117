import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setSearch } from "../features/search/searchSlice";
import LogoImg from "../assets/images/logo.svg";

const Header = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  return (
    <nav className="container relative py-3">
      <div className="flex items-center justify-between">
        <Link to="/">
          <img src={LogoImg} alt="lws" />
        </Link>
        <div
          className="flex-1 max-w-xs search-field group"
          style={{ color: "black" }}
        >
          <i className="fa-solid fa-magnifying-glass search-icon group-focus-within:text-blue-500"></i>
          <input
            style={{ color: "black" }}
            type="text"
            placeholder="Search Task"
            className="search-input"
            id="lws-searchTask"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              dispatch(setSearch(e.target.value));
            }}
          />
        </div>
      </div>
    </nav>
  );
};

export default Header;
