import { configureStore } from '@reduxjs/toolkit';

import { apiSlice } from "../features/api/apiSlice";
import projectsSlice from "../features/projects/projectsSlice";
import searchSlice from "../features/search/searchSlice";


export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    projects: projectsSlice,
    search: searchSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(apiSlice.middleware),
});