import React from "react";
import SidebarMembers from "./SidebarMembers";

import SidebarProjects from "./SidebarProjects";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <SidebarProjects />
      <SidebarMembers />
    </div>
  );
};

export default Sidebar;
