import React from "react";

import { useGetTeamsQuery } from "../../features/teams/teamsApi";

const SidebarMembers = () => {
  const { data: teams, isLoading, isError, error } = useGetTeamsQuery();

  return (
    <div className="mt-8">
      <h3 className="text-xl font-bold">Team Members</h3>
      <div className="mt-3 space-y-4">
        {isLoading && <div>Loading...</div>}
        {isError && <div>{error.message}</div>}
        {!isLoading && !isError && teams.length > 0 ? (
          teams.map((team) => (
            <div className="checkbox-container" key={team.id}>
              <img src={team.avatar} alt="lws" className="team-avater" />
              <p className="label">{team.name}</p>
            </div>
          ))
        ) : (
          <div align="center">
            <strong style={{ color: "red" }}>No Teams Found</strong>
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarMembers;
